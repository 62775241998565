import Vue from 'vue'
// import VueResource from 'vue-resource'
import App from './App.vue'
import router from '../src/router/router'
import store from './store/store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import dialog from './mixins/dialog'
import DatetimePicker from 'vuetify-datetime-picker'
import service from './services/Service'
import { API_URL } from './config/index.js'
import './assets/main.css'
import './plugins/vuetify-money.js'
import UUID from 'vue-uuid'
import VueEllipseProgress from 'vue-ellipse-progress'
import notification from './mixins/notification'

Vue.use(VueEllipseProgress)

Vue.use(UUID)
Vue.use(DatetimePicker)

// config file with base endpoint url
service.defaults.baseURL = `${API_URL}`

const userToken = localStorage.getItem('token')
if (userToken) {
  service.defaults.headers.common.Authorization = 'Bearer ' + userToken
}
service.defaults.headers['Content-type'] = 'application/json'
service.defaults.headers.Accept = 'application/json'

Vue.mixin({
  mixins: [dialog, notification],
})

new Vue({
  router: router,
  store,
  vuetify,
  // i18n,
  render: h => h(App),
}).$mount('#app')
