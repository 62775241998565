const notification = {
    methods: {
        setNotification (payload) {
            const { message, type } = payload
            this.$store.dispatch('setNotification', { message, type })
        },
    },
}

export default notification
