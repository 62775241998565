import axios from 'axios'

const _axios = axios.create({

})

_axios.interceptors.response.use((response) => {
     if (response.data.errors) {
         throw new Error(response.data.message)
     } else {
         return response
     }
  }, function (error) {
      if (error.response.status === 401) {
          localStorage.removeItem('token')
          window.location = '/login'
      }
      return Promise.reject(error)
})

export default _axios
