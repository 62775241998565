import { getAllItems } from '../../services/SubscriptionsService'

const subscriptions = {
    namespaced: true,
    state: () => ({
        list: [],
        isLoading: false,
    }),

    mutations: {
      SET_LOADING (state, payload) {
        state.isLoading = payload
      },
      SET_LIST (state, payload) {
        state.list = payload
      },
    },
    actions: {
      changeStatus ({ commit }, payload) {
        commit('SET_LOADING', payload)
      },
      /**
       *
       *
       *
       */
      async getAllData ({ commit }) {
        commit('SET_LOADING', true)
        const response = await getAllItems()
        const httpData = response.data
        const { data } = httpData
        commit('SET_LIST', Array.isArray(data) ? data : Object.values(data))
        commit('SET_LOADING', false)
      },
    },
  }

  export default subscriptions
