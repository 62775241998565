import { getAllItems, fetchOneItem, addItem, updateItem, deleteItem } from '../../services/PackageService'
import route from '../../router/router'

const _package = {
    namespaced: true,
    state: () => ({
        list: [],
        singleItem: {},
        isLoading: false,
    }),
    mutations: {
      SET_LOADING (state, payload) {
        state.isLoading = payload
      },
      SET_LIST (state, payload) {
        state.list = payload
      },
      SET_ITEM (state, payload) {
        state.singleItem = payload
      },
    },
    actions: {
      changeStatus ({ commit }, payload) {
        commit('SET_LOADING', payload)
      },
      /**
       *
       *
       *
       */
      async getAllData ({ commit }) {
        commit('SET_LOADING', true)
        const response = await getAllItems()
        const httpData = response.data
        const { data } = httpData
        commit('SET_LIST', Array.isArray(data) ? data : Object.values(data))
        commit('SET_LOADING', false)
      },
      /**
       *
       *
       *
       */
      async getById ({ commit }, payload) {
        commit('SET_LOADING', true)
        const response = await fetchOneItem(payload)
        const httpData = response.data
        const { data } = httpData
        commit('SET_ITEM', { ...data, features: arrayOfStrings(data.features, 'feature') })
        commit('SET_LOADING', false)
      },
      /**
       *
       *
       *
       */
      async addItem ({ commit, dispatch }, payload) {
        commit('SET_LOADING', true)
        try {
          await addItem(payload)
          // eslint-disable-next-line no-unused-vars
          dispatch('notification/setNotification', { message: 'تم الإضافة بنجاح', type: 'success' }, { root: true })
          route.push('/packages')
        } catch (error) {
          dispatch('notification/setNotification', { message: error.message, type: 'error' }, { root: true })
        } finally {
          commit('SET_LOADING', false)
        }
      },
      /**
       *
       *
       *
       */
      async updateItem ({ commit, dispatch }, { id, data }) {
        commit('SET_LOADING', true)
        try {
          await updateItem(id, data)
          dispatch('notification/setNotification', { message: 'تم التعديل بنجاح', type: 'success' }, { root: true })
          route.push('/packages')
        } catch (error) {
          dispatch('notification/setNotification', { message: error.message, type: 'error' }, { root: true })
        } finally {
          commit('SET_LOADING', false)
        }
      },
      /**
       *
       *
       *
       */
      async deleteItem ({ commit, state, dispatch }, payload) {
        const id = payload
        commit('SET_LOADING', true)
        try {
          await deleteItem(id)
          commit('SET_LIST', state.list.filter(plan => plan.id !== id))
          dispatch('notification/setNotification', { message: 'تم الحذف بنجاح', type: 'success' }, { root: true })
        } catch (error) {
          dispatch('notification/setNotification', { message: error.message, type: 'error' }, { root: true })
        } finally {
          commit('SET_LOADING', false)
        }
      },
      setDefaultItem ({ commit }, payload) {
        commit('SET_ITEM', payload)
      },
    },
  }

  function arrayOfStrings (array, prop) {
    return array.map(item => item[prop])
  }

  export default _package
