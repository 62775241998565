import Service from './Service'
import { API_URL } from '../config'

const resource = `${API_URL}/packages`

export const getAllItems = () => Service.get(`${resource}`)
export const fetchOneItem = (id) => Service.get(`${resource}/${id}`)
export const addItem = (data) => Service.post(`${resource}`, data)
export const updateItem = (id, data) => Service.put(`${resource}/${id}`, data)
export const deleteItem = (id) => Service.delete(`${resource}/${id}`)
