import Vue from 'vue'
import Router from 'vue-router'
import { deleteAllCookies } from '../Utils/cookies'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // parseQuery: qs.parse,
  // stringifyQuery: (query) => qs.stringify(query,{}),
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    // Login
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/pages/login'),
    },
    {
      name: 'forgotPassword',
      path: '/forgot-password',
      component: () => import('@/pages/ResetPassword/ForgotPassword'),
    },
    {
      name: 'EnterCode',
      path: '/enter-code',
      component: () => import('@/pages/ResetPassword/EnterCode'),
    },
    {
      name: 'Enter New Password',
      path: '/enterPass',
      component: () => import('@/pages/ResetPassword/NewPassword'),
    },
    {
      path: '/',
      redirect: '/',
      // beforeEnter: routerGuard,
      component: () => import('@/views/dashboard/Index'),
      children: [
        // unAuthorized Page
        {
          path: '/notAuthorized',
          name: 'notAuthorized',
          component: () => import('../pages/NotAuthorized.vue'),
        },
        // Home
        {
          name: 'statistics',
          path: '/',
          meta: { requiresAuth: true },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'packages',
          path: '/packages',
          meta: { requiresAuth: true },
          component: () => import('@/views/dashboard/packages/Index'),
        },
        {
          name: 'package',
          path: '/package/:id?',
          meta: { requiresAuth: true },
          component: () => import('@/views/dashboard/packages/PackageForm'),
        },
        {
          name: 'subscriptions',
          path: '/subscriptions',
          meta: { requiresAuth: true },
          component: () => import('@/views/dashboard/subscriptions/Index'),
        },
        { path: '*', redirect: '/' },
      ],
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  /**
   * * is this router require a token ?
   */
  if (to.meta.requiresAuth) {
    /**
     * * if the user does not has a token, redirect him to the login page
     */
    if (!token) {
      deleteAllCookies()
      document.location.href = '/login'
    } else {
        next()
    }
  } else {
    next()
  }
})

export default router
